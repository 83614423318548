const resource = {
    en: {
        reset_password: 'Reset Password',
        email: 'Email Address',
        password: 'Password',
        password_confirmation: 'Password Confirmation',
        reset_password_button: 'Reset',
        logged_in: {
            heading: 'You are logged in',
            message: 'Please change the password in your account settings.',
            home_button: 'Back to Home',
        },
        invalid_token: {
            heading: 'Invalid URL',
            message: 'The URL that you used to reset your password is invalid or expired.',
            home_button: 'Back to Home',
        },
    },
    de: {
        reset_password: 'Passwort Rücksetzen',
        email: 'Email Adresse',
        password: 'Passwort',
        password_confirmation: 'Passwort wiederholen',
        reset_password_button: 'Rücksetzen',
        logged_in: {
            heading: 'Sie sind schon eingeloggt',
            message: 'Verwenden Sie bitte die Funktion in Ihrem Profil um Ihr Passwort zu ändern.',
            home_button: 'Zurück zur Homepage',
        },
        invalid_token: {
            heading: 'Ungültiger Link',
            message: 'Der Link, den Sie verwendet ist abgelaufen oder ungültig.',
            home_button: 'Zurück zur Homepage',
        },
    }
}

export default resource;