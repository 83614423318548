<template>
    <div v-if="valid_token !== null">
        <div class="authentication-card" v-if="user">
            <h1 class="authentication-heading" v-html="resource[lang].logged_in.heading" />
            <p class="authentication-text" v-html="resource[lang].logged_in.message" />
            <Button stretch rounded submit
                class="authentication-button" 
                color="primary" 
                :label="resource[lang].logged_in.home_button" 
                @click="$router.push('/')"
            />
        </div>

        <div class="authentication-card" v-if="!valid_token">
            <h1 class="authentication-heading" v-html="resource[lang].invalid_token.heading" />
            <p class="authentication-text" v-html="resource[lang].invalid_token.message" />
            <Button stretch rounded submit
                class="authentication-button" 
                color="primary" 
                :label="resource[lang].invalid_token.home_button" 
                @click="$router.push('/')"
            />
        </div>

        <div v-if="!user && valid_token">
            <form class="authentication-card" @submit.prevent="resetPassword">
                <h1 class="authentication-heading" v-html="resource[lang].reset_password" />
                <AuthenticationInputText required
                    class="authentication-input" 
                    v-model="email" 
                    :placeholder="resource[lang].email_address"
                />
                <AuthenticationInputText required password
                    class="authentication-input" 
                    v-model="password" 
                    :placeholder="resource[lang].password" 
                />
                <AuthenticationInputText required password
                    class="authentication-input" 
                    v-model="password_confirmation" 
                    :placeholder="resource[lang].password_confirmation" 
                />
                <Button stretch rounded submit
                    class="authentication-button" 
                    color="accent" 
                    :label="resource[lang].reset_password_button" 
                />
            </form>

        </div>
    </div>
</template>

<script>
import { useQuasar } from 'quasar';
import { mapGetters } from 'vuex';

import resource from '@/assets/resources/reset-password-resource'

import AuthenticationInputText from '@/components/basic/AuthenticationInputText'
import Button from '@/components/basic/Button'

import { promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        AuthenticationInputText,
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
           lang: lang,

           email: '',
           password: '',
           password_confirmation: '',

           valid_token: null,

           resource
       } 
    },
    computed: {
        ...mapGetters(['user']),
        token() {
            return this.$route.params.token ? this.$route.params.token : null;
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    created() {
        this.validateForgotPasswordToken();
    },
    methods: {
        // Requests
        validateForgotPasswordTokenRequest() {
            const request_body = {
                token: this.token,
            }
            return promisePostRequest('validate-forgot-password-token', request_body);
        },
        resetPasswordRequest() {
            const request_body = {
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                token: this.token,
            }
            return promisePostRequest('reset-password', request_body);
        },

        // Actions
        async validateForgotPasswordToken() {
            this.$store.dispatch('loading', true);
            const response = await this.validateForgotPasswordTokenRequest();
            this.$store.dispatch('loading', false);

            if (response.data.status === 'success' || response.status === 200) {
                this.valid_token = true;
            } else {
                this.valid_token = false;
            }
        },
        async resetPassword() {
            this.$store.dispatch('loading', true);
            const response = await this.resetPasswordRequest();
            this.$store.dispatch('loading', false);

            if (response.data.status === 'success' || response.status === 200) {
                this.$router.push('/reset-password-confirmation')
            } else {
                if (response.response.status == 422) {
                    for (let issue in response.response.data) {
                        this.quasar.notify({
                            position: 'bottom-left', 
                            message: `${issue}: ${response.response.data[issue]}`, 
                            color: 'negative'
                        });
                    }
                } else {
                    this.quasar.notify({
                        position: 'bottom-left', 
                        message: 'service not available', 
                        color: 'negative'
                    });
                }
            }
        }
    }
}
</script>